@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alike&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Hahmlet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

*:focus {
	border-color: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

/* ::-webkit-scrollbar {
	width: 10px;

} */

/* ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
} */

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* .main {
	position: relative;
} */

.react-reveal {
	padding: 0px !important;
	margin: 0px !important;
}

.navbar {
	/* background-color: #1c2f34 !important; */

	background-image: linear-gradient(to bottom, #161e20, rgb(43, 86, 91, 0.5));
	flex-wrap: wrap;
	padding: 0px !important;

	/* position: fixed !important;
	width: 100% !important; */
}

.nav_bg {
	background-image: url(./assets/banner.jpg);
	background-size: cover;
	background-position: center center no-repeat !important;
}

.navbar-collapse {
	justify-content: space-between;
	padding-left: 20px !important;
}

.fixed-nav-bar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	height: 65px !important;
}

.fa-copy {
	font-size: 12px !important;
	line-height: 16px;
	color: white;
}

.fa-copy:hover {
	cursor: pointer;
}

.contract_address {
	font-size: 16px;
	color: #bfbfbf;
	font-weight: 300px;
	line-height: 16px;
	font-family: 'Alike', sans-serif;
}

.contract_link {
	text-decoration: none !important;
	color: white;
	font-weight: 300px;
	font-size: 14px;
	line-height: 14px;
	font-family: 'Alike', sans-serif;
}

.contract_link:hover {
	cursor: pointer;
	text-decoration: none !important;
	color: white;
	font-weight: 300px;
	line-height: 14px;
	font-family: 'Alike', sans-serif;
}

@media (max-width: 576px) {
	.contract_address {
		font-size: 14px !important;
	}

	.contract_link {
		font-size: 12px !important;
	}

	.fa-copy {
		font-size: 10px !important;
	}
}

.fixed-nav-bar2 {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
}

.token_adress_bar {
	height: 41px !important;
	background: #3b3b3b !important;
}

.main_navbar .navbar-toggler {
	background: black !important;
	border-radius: 0px !important;
	padding: 6px !important;
}

.second_navbar .navbar-toggler {
	background: black;
	border-radius: 0px !important;
	padding: 4px 6px !important;
}

.fixed-navbar2 {
	overflow: hidden;
	background-color: #333;
	position: fixed;
	/* Set the navbar to fixed position */
	top: 0;
	/* Position the navbar at the top of the page */
	width: 100%;
}

a.nav-link {
	font-family: 'Alike', serif;
	font-size: 17px !important;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	color: white !important;
	display: flex !important;
	justify-content: start !important;
}

a.nav-link:hover {
	color: #0a86b9 !important;
}

.launch_btn {
	padding: 10px 10px 10px 10px;
	background-image: linear-gradient(90deg, #011037 0%, #0a86b9 100%);
	border-radius: 20px !important;
	font-family: 'Alike', Sans-serif;
	font-size: 20px;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	border: 1px solid white;
}

.sale_btn {
	padding: 10px 40px 10px 40px;
	background-image: linear-gradient(90deg, #011037 0%, #0a86b9 100%);
	border-radius: 20px !important;
	font-family: 'Alike', Sans-serif;
	font-size: 20px;
	color: white;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	border: 1px solid white;
}

.connect_btn {
	padding: 10px 10px 10px 10px;
	background-image: linear-gradient(90deg, #011037 0%, #08668b 100%);
	border-radius: 10px !important;
	font-family: 'Alike', Sans-serif;
	font-size: 20px;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	border: 1px solid white;
}

.fa-list {
	color: white;
}

.fa-window-close {
	color: white;
}

.connect_btn:hover {
	transform: scale(1.2);
	transition: 0.7s;
}

/* .navbar-collapse {
	background: #0a86b9 !important;
} */
/* .navbar__logo {
	width: 10% !important;
} */
.header_token_section {
	height: 41px !important;
	margin: 0px !important;
	background: #a9a9aa !important;
}

@media only screen and (max-width: 991px) {
	.navbar-collapse {
		background: #0a86b9;
	}

	.navbar-nav {
		border-bottom: 1px solid white;
	}

	.main_navbar {
		padding-bottom: 0px !important;
		padding-top: 20px !important;
	}

	.second_navbar {
		padding-bottom: 0px !important;
	}

	.navbar .container-fluid {
		padding: 0px;
	}

	.second_navbar .container-fluid {
		padding: 0px !important;
	}

	.second_navbar .navbar-nav {
		border: none !important;
	}
}

@media screen and (max-width: 367px) {
	.second_navbar .container-fluid {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.navbar_second_logo {
		width: 172px !important;
	}
}

/* Steampad Section */

.steampad_section {
	/* background-image: url(./assets/main.jpg) !important; */
	/* background: linear-gradient(
			0deg,
			rgba(241, 239, 239, 0.288),
			rgba(34, 31, 31, 0.116)
		),
		url(./assets/main.jpg); */
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), #152d3752);
	/* margin-top: 65px !important; */
	height: 850px !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	position: relative;
	/* margin-top: 76px !important; */
}

.steampad_section:after {
	content: '';
	background: url(./assets/A1.jpeg) no-repeat center center;
	background-size: cover;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0.9;
	/* Here is your opacity */
}

.steampad_section img {
	width: 18% !important;
}

.steampad_section_heading {
	font-size: 100px !important;
	font-family: 'Alike', serif;
	color: #00cdff !important;
}

.steampad_sub_heading {
	font-size: 24px !important;
	font-family: 'Hamlet', Sans-serif;
	color: white !important;
	overflow-wrap: break-word !important;

	line-height: 40px !important;
}

@media screen and (max-width: 1024) {
	.main_row {
		flex-direction: column !important;
	}

	.steampad_section img {
		width: 30% !important;
	}

	.steampad_section_heading {
		font-size: 25px !important;
	}

	.steampad_sub_heading {
		font-size: 15px !important;
		font-family: 'Alike', serif;
		color: white !important;
	}
}

@media screen and (max-width: 600px) {
	.main_row {
		flex-direction: column !important;
	}

	.steampad_section img {
		width: 40% !important;
	}

	.steampad_section_heading {
		font-size: 50px !important;
	}

	.steampad_sub_heading {
		font-size: 20px !important;
		font-family: 'Alike', serif;
		color: white !important;
	}

	.steampad_section {
		align-items: center !important;
		height: 300px !important;
	}
}

.launchpad_sub_section {
	background-color: transparent;
	background-image: linear-gradient(180deg, #2a5663ee 100%, #171f21 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;
}

/* Metaverse section */
.metaverse_section {
	/* background-image: url(./assets/meraverse_bg.jpg) !important; */
	/* background: #141414 !important; */
	background-color: transparent;
	background-image: linear-gradient(180deg, #161616 30%, #2a5663ee 100%),
		url(./assets/manifesto_main.jpg);
	/* min-height: 500px !important; */
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;

	mix-blend-mode: multiply;
	/* background-size: cover !important;
	background-repeat: no-repeat !important; */
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.background_text {
	font-size: 220px !important;
	font-family: 'Allerta Stencil' !important;
	color: #ffffff29 !important;
	line-height: 250px !important;
	overflow-wrap: break-word !important;
	line-height: 264px !important;
}

.sub_heading {
	color: #00cdff !important;
	font-weight: 500px !important;
}

.metaverse_sub_heading {
	font-size: 40px !important;
	margin-top: -150px !important;
	color: white !important;
	font-family: 'Alike', serif;
}

.metaverse_sub_heading p {
	overflow-wrap: break-word;
}

.metaverse_row {
	min-height: 503px !important;

}

.metaverse_p {
	font-family: 'Hahmlet', serif !important;
	color: white !important;
	text-align: center !important;
	font-size: 20px !important;
	line-height: 30px !important;
}

.metaverse_s {
	font-family: 'Hahmlet', serif !important;
	color: white !important;
	text-align: center !important;
	font-size: 18px !important;
	line-height: 30px !important;
	padding-bottom: 10px;
}

.metaverse_s1 {
	font-family: 'Hahmlet', serif !important;
	color: white !important;
	text-align: left !important;
	font-size: 14px !important;
	line-height: 30px !important;

}

.metaverse_s11 {
	font-family: 'Hahmlet', serif !important;
	color: white !important;
	text-align: left !important;
	font-size: 8px !important;

}

.metaverse_s2 {
	font-family: 'Hahmlet', serif !important;
	color: white !important;
	text-align: center !important;
	font-size: 14px !important;
	line-height: 30px !important;
	padding-top: 10px;
	cursor: pointer;
}

.chart_img {
	width: 100% !important;
	animation-name: mymove;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-duration: 2s;
}

.field:focus {
	outline: 0;
}

.field {
	font: inherit;
	letter-spacing: inherit;
	color: currentColor;
	padding: 4px 0 5px;
	border: 0;
	box-sizing: content-box;
	background: none;
	height: 1.4375em;
	margin: 0;
	-webkit-tap-highlight-color: transparent;
	display: block;
	min-width: 0;
	width: 100%;
	animation-name: mui-auto-fill-cancel;
	animation-duration: 10ms;
}

input:focus-visible {
	outline-offset: 0px;
}

input {
	writing-mode: horizontal-tb !important;
	text-rendering: auto;
	word-spacing: normal;
	text-transform: none;
	text-indent: 0px;
	text-shadow: none;
	text-align: start;
	appearance: auto;
	-webkit-rtl-ordering: logical;
	cursor: text;
}

.field_top {
	font-family: Courier;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4375em;
	color: #ffffff;
	box-sizing: border-box;
	cursor: text;
	display: inline-flex;
	position: relative;
	align-items: center;
	-webkit-box-align: center;
}

.field_top1 {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
	vertical-align: top;
	font-weight: 600;
}

.field_text {
	margin: 0;
	font-family: Courier;
	font-size: 0.875rem;
	line-height: 1.43;
	color: #ffffff;
	white-space: pre-wrap;
	font-weight: 600;
}

.field_text_top {
	display: flex;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
}

.p_field {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background: #d8d8d81A;
	border-radius: 8px;

}

.p_field_top {
	margin: 0;
	margin-top: 20px;
}

.button.Mui-disabled {
	/* color: rgba(255, 255, 255, 0.3);*/
	box-shadow: none;
	/*background-color: rgba(255, 255, 255, 0.12);*/
}

.button:hover {
	text-decoration: none;
}

.button.Mui-disabled {
	pointer-events: none;
	cursor: default;
}

.button {
	color: white;
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	user-select: none;
	vertical-align: middle;
	-webkit-appearance: none;
	font-weight: 500;
	line-height: 1.75;
	text-transform: uppercase;
	min-width: 64px;
	padding: 8px 22px;
	border-radius: 4px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-family: DelaGothicOne;
	font-size: 18px;
	background-image: linear-gradient(90deg, #011037 0%, #0a86b9 100%);
}

.button_top {
	display: flex;
	flex-direction: column;
	padding-top: 40px;
}

.form {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	flex: 1;
	border: 1px solid #32fef7;
	padding: 32px;
	background-color: #2835415C;
}

@keyframes mymove {
	from {
		transform: translateY(0px);
	}

	to {
		transform: translateY(20px);
	}
}

@media screen and (max-width: 992px) {
	.background_text {
		font-size: 105px !important;
		line-height: 80px !important;
	}

	.metaverse_sub_heading {
		font-size: 30px !important;
		margin-top: -50px !important;
	}

	.metaverse_p {
		font-size: 16px !important;
	}

	.chart_img {
		width: 90% !important;
	}
}

@media (max-width: 768px) {
	.chart_img {
		width: 70% !important;
	}

	.metaverse_p {
		font-size: 14px !important;
	}
}

@media screen and (max-width: 768px) {
	.background_text {
		font-size: 43px !important;
		line-height: 52px !important;
	}

	.metaverse_sub_heading {
		font-size: 20px !important;
		margin-top: -30px !important;
		line-height: 20px !important;
	}

	.metaverse_p {
		font-size: 14px !important;
		line-height: 33px !important;
	}

	.chart_img {
		width: 80% !important;
	}
}

/* Ecosystem Section */
.ecosystem_section {
	/* background-image: url(./assets/meraverse_bg.jpg) !important; */
	/* background: #141414 !important; */
	background-color: transparent !important;
	/* background-image: linear-gradient(0deg, #161616 0%, #00cdff38 100%),
		url(./assets//manifesto_main.jpg); */
	background-color: transparent;
	background-image: linear-gradient(180deg, #2a5663ee 30%, #2a5663ee 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;

	mix-blend-mode: multiply;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.eco_col {
	height: 446px !important;
	background: #000000 !important;

}

.eco_col__right_img {
	height: 446px !important;
	background-image: url(./assets/controller.png);
	background-position: center right !important;
	background-size: cover;
	opacity: 0.4 !important;
}

.eco_col__left_img {
	height: 446px !important;
	background-image: url(./assets/crypto.jpg);
	background-position: center center !important;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 0.4 !important;
}

.eco_col__right2_img {
	height: 446px !important;
	background-image: url(./assets/blockchain.jpeg);
	/* background-position: center !important;
	background-size: cover;*/
	opacity: 0.4 !important;
	background-position: center center !important;
	background-repeat: no-repeat;
	background-size: cover;
}

.fa-rocket {
	font-size: 40px !important;
	color: #00cdff !important;
	transition: 0.7s;
}

.fa-rocket:hover {
	transform: scale(1.3);
}

.fa-gamepad {
	font-size: 40px !important;
	color: #00cdff;
	transition: 0.7s;
}

.fa-gamepad:hover {
	transform: scale(1.3);
}

.fa-tachometer-alt {
	font-size: 40px !important;
	color: #00cdff !important;
	transition: 0.7s;
}

.fa-tachometer-alt:hover {
	transform: scale(1.3);
}

.eco_col h5 {
	color: #00cdff;
	font-family: 'Hahmlet', Sans-serif;
	font-size: 34px;
	font-weight: bold !important;
	line-height: 41px;
}

.eco_col p {
	color: #ffffff;
	font-size: 20px !important;
	line-height: 33px;
	font-family: 'Hahmlet', Sans-serif;
	font-weight: 400;
	text-align: justify;
	padding-left: 20px;
	padding-right: 20px;
}

@media screen and (max-width: 768px) {
	.eco_col p {
		font-size: 14px !important;
	}

	.eco_col h5 {
		font-size: 15px !important;
	}

	.eco_col {
		height: auto !important;
	}

	.eco_col__right_img {
		height: 305px !important;
	}

	.eco_col__left_img {
		height: 305px !important;
	}

	.eco_col__right2_img {
		height: 305px !important;
	}
}

/* Roadmap  Section*/

.roadmap_section {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.roadmap_section h3 {
	font-size: 18px !important;
	color: #00cdff;
}

.rectangle_box {
	width: 30px !important;
	height: 10px !important;
	background: #00cdff;
	margin-left: 80px !important;
}

.fa-circle {
	font-size: 8px;
	color: #aaa2a2;
	/* margin-left: 12px !important; */
}

.main_circle {
	font-size: 15px !important;
	color: #00cdff !important;
}

/* .roadmap_section p {
	font-size: 20px;
	font-family: 'Hahmlet', Sans-serif;
	color: #e8ebec;
	font-weight: 400px !important;
} */
.roadmap_heading {
	/* margin-left: -100px !important; */
	font-weight: 900;
	font-size: 18px;
	line-height: 22px !important;
	color: #00cdff !important;
	font-family: 'Hahmlet', sans-serif;
}

.roadmap_p {
	/* margin-left: -100px !important; */
	font-size: 20px;
	font-family: 'Hahmlet', Sans-serif;
	color: #e8ebec;
	font-weight: 400px !important;
	margin: 0px !important;
}

@media (max-width: 992px) {
	/* .roadmap_heading {
		margin-left: -70px !important;
	} */
}

@media (max-width: 768px) {}

@media (max-width: 568px) {
	.roadmap_p {
		font-size: 14px !important;
		line-height: 30px !important;
	}

	.fa-circle {
		line-height: 8px !important;
	}
}

/* @media screen and (max-width: 600px) {
	.roadmap_section p {
		font-size: 14px;
		font-family: 'Hahmlet', Sans-serif;
		color: #e8ebec;
		font-weight: 400px !important;
	}
} */

/* Manefesto section */
.manifesto_section {
	/* background: #141414 !important; */

	background-color: transparent;
	background: linear-gradient(180deg, #161616ef 0%, #295863 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;
	mix-blend-mode: darken;
	/* background-image: linear-gradient(180deg, #161616 0%, #00cdff38 100%),
		url(./assets/manifesto_main.jpg); */
	/* background: linear-gradient(rgba(17, 17, 17, 0.603), rgba(10, 0, 0, 0.74)),
		url(./assets//manifesto.jpg); */
	/* min-height: 500px !important; */
	/* background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important; */

	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.manifesto_row {
	/* background: linear-gradient(8, 8, 8, 0.5) !important; */
	background: linear-gradient(rgba(17, 17, 17, 0.603), rgba(10, 0, 0, 0.74)),
		url(./assets//manifesto.jpg);
	background-size: cover !important;
	background-position: right center !important;
	background-repeat: no-repeat !important;
	mix-blend-mode: luminosity;

	/* linear-gradient(
			to bottom,
			rgba(245, 246, 252, 0.52),
			rgba(117, 19, 93, 0.73)
		), */
}

.manifesto_section .manifesto_body_p {
	font-size: 20px !important;
	line-height: 33px !important;
	color: white !important;
	font-family: 'Hahmlet' !important;
	text-align: justify !important;
}

@media (max-width: 768px) {
	.manifesto_section .manifesto_body_p {
		font-size: 14px !important;
	}
}

/* Token Section */

.token_section {
	/* background-color: transparent;
	background-image: linear-gradient(180deg, #2a5663ee 30%, #141515 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover; */
	/* background: #141414 !important;
	background-size: cover !important;
	background-repeat: no-repeat !important; */
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.token_main_img {
	width: 100%;
}

.spd_h2 {
	font-family: 'Alike', Sans-serif;
	font-size: 18px;
	font-weight: 700;
	color: white;
	line-height: 0.8;
}

.tokenomic_h2 {
	color: white;
	font-weight: 400px;
	font-family: 'Alike', Sans-serif;
	font-size: 12px;
}

.token_logo_img {
	width: 40% !important;
}

.token__value {
	margin: 0px 0px 0px 0px;
	background-color: #0464a3;
	border-style: solid;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px !important;

	border: 1px solid white !important;
	padding-left: 50px !important;
	padding-right: 50px !important;
}

.token_value_h2 {
	font-family: 'Alike', Sans-serif;
	font-size: 15px;
	font-weight: 400;
	color: white;
}

.stpd_img {
	width: 100%;
}

.joinus_section {
	height: 552px;
	/* background: url(./assets/joinus.jpg); */
	background-color: transparent;

	background-image: linear-gradient(0deg, #161616c9 30%, #2a5663da 100%),
		url(./assets/joinus.jpg);
	/* background-image: linear-gradient(0deg, #000000 0%, #00ccff63 100%),
		url(./assets/joinus.jpg); */
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center;
}

.joinus_section span {
	font-size: 32px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 51px !important;
}

.joinus_section p {
	font-size: 22px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;
	line-height: 35px !important;
}

.joinus_section .btn {
	text-align: center;
	background: #0a86b9;
	border-radius: 20px !important;
	border: none !important;
	font-size: 13px !important;
	font-family: 'Varela Round', sans-serif;
}

.joinus_section .btn:hover {
	background: #67c9f0;
}

@media (max-width: 768px) {
	.joinus_section p {
		font-size: 16px !important;
	}
}

/* Footer  */

.footer_row {
	/* background: #3b4244 !important; */
	background: linear-gradient(180deg, #161616 30%, #2a5663ee 100%) !important;
}

.footer_row p {
	font-size: 16px !important;
	font-family: 'Hahmlet', Sans-serif;
	color: white !important;
	margin: 0px !important;
}

.footer_img {
	width: 50%;
}

.copy_row {
	background: black !important;
}

.copy_p {
	font-size: 20px !important;
	font-family: 'Hahmlet', Sans-serif;
	color: white !important;
	margin: 0px !important;
}

.footer_row a {
	text-decoration: none !important;
	color: white !important;
	font-size: 13px !important;
	font-weight: 300px !important;
	font-family: 'Varela Round', sans-serif;
}

.footer_row a:hover {
	color: #084af3 !important;
}

.footer_row h2 {
	font-size: 25px !important;
	font-family: 'Hahmlet', sans-serif;
	font-weight: 400px !important;
	color: white !important;
}

.footer_icon {
	background: #1da1f2;

	border-width: 0px 0px 0px 0px;
	padding: 10px 10px 10px 10px;
	border-radius: 50% !important;
}

.fa-twitter {
	font-size: 20px !important;
	color: white !important;
}

.fa-twitter:hover {
	color: #084af3 !important;
}

.fa-facebook {
	font-size: 20px !important;
	color: white !important;
}

.fa-instagram {
	font-size: 20px !important;
	color: white !important;
}

.fa-telegram {
	color: white !important;
	font-size: 20px !important;
}

@media (max-width: 768px) {
	.copy_p {
		font-size: 14px !important;
	}
}

.roadmap_token {
	background-color: transparent;
	background-image: linear-gradient(180deg, #2a5663ee 30%, #171f21 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;
}

/* Launchpad */

.launchpad_section {
	background: #141416 !important;
}

.trading_section {
	margin-top: 76px !important;
	min-height: 80vh !important;
}

.trading_main {
	overflow: auto !important;
	display: flex;
	/* margin-top: 22px; */
	padding: 40px;
	-webkit-box-pack: start;
	justify-content: center !important;

	width: 100%;
	max-width: 100vw;
}

.trading_col {
	min-width: 260px !important;
}

.trade_title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 12px;
	color: rgb(55, 114, 255);
	flex: 0 0 auto;
	order: 0;

	-webkit-box-flex: 0;
	margin: 4px 0px;
	line-height: 12px !important;
}

.trade_growth_title {
	color: rgb(177, 181, 196) !important;
	font-family: Poppins-Regular, sans-serif;
	font-size: 12px !important;
	font-weight: 600 !important;
	line-height: 20px !important;
}

.trade_growth_fail {
	color: rgb(247, 46, 46);
	font-family: Poppins-Regular, sans-serif;
	font-size: 12px !important;
	font-weight: 600 !important;
	line-height: 20px !important;
}

.trade_report_value {
	font-size: 20px !important;
	font-family: Poppins;
	font-weight: 600;
	line-height: 30px;
	color: #fcfcfd;
}

.trade_growth_sub {
	font-size: 12px;
	line-height: 20px;
	font-family: Poppins-Regular, sans-serif;
	color: #b1b5c4;
	font-weight: 600;
}

@media (min-width: 990px) and (max-width: 1040px) {
	.trading_section {
		margin-top: 93px !important;
	}
}

@media screen and (max-width: 992px) {
	.trading_section {
		margin-top: 54px !important;
	}
}

.nft_collection_section {
	padding: 40px !important;
	width: 100% !important;
}

.allocation_section {
	margin-top: 60px !important;
	justify-content: center !important;
}

.allocation_section .heading {
	font-size: 60px !important;
	line-height: 67px !important;
	font-family: Poppins-Regular, sans-serif;
	font-weight: 700;
	-webkit-text-fill-color: transparent;
	background: linear-gradient(-75deg,
			rgb(21, 178, 244) 0%,
			rgb(149, 70, 194) 50%,
			rgb(253, 121, 66) 75%,
			text);
}

/* LaunchPad Section */

.launchpad_banner {
	min-height: 480px !important;
	background: linear-gradient(180deg, #2a566334 100%, #161616 30%),
		url(./assets/banner.jpg);
	background-size: cover;
	background-position: center center no-repeat !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.launch_img {
	width: 100% !important;
	animation-name: stretch;
	animation-duration: 5s;
	animation-timing-function: ease-out;
	animation-delay: 0;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-fill-mode: none;
	animation-play-state: running;
}

.banner_heading {
	font-family: 'Alike', sans-serif;
	font-size: 48px !important;
	line-height: 77px !important;
	color: #00cdff !important;
	font-weight: 600;
}

.banner_sub_heading {
	font-family: 'Alike', sans-serif;
	font-size: 20px !important;
	line-height: 30px !important;
	color: white !important;
	font-weight: 400;
	text-align: justify;
}

@media (max-width: 576px) {
	.banner_heading {
		font-size: 30px !important;
	}

	.banner_sub_heading {
		font-size: 14px !important;
	}

	.launch_img {
		width: 80% !important;
	}
}

@keyframes stretch {
	0% {
		transform: scale(0.3);
	}

	100% {
		transform: scale(1);
	}
}

/* Distribution */
.distribution_heading {
	color: #00cdff;
	font-size: 56px !important;
	font-family: 'Alike', sans-serif;
	line-height: 77px !important;
	font-weight: 600;
	margin-bottom: 48px !important;
}

.distribution_sub_heading {
	font-family: 'Alike', sans-serif;
	font-size: 40px;
	font-weight: 600;
	/* line-height: 36px; */
	color: #00cdff;
	text-align: start;
}

.distribution_sub_text {
	font-family: 'Alike', sans-serif;
	font-size: 20px !important;
	font-weight: 400;
	line-height: 30px;
	color: white;
	text-align: justify;
}

.distribution_sub_text1 {
	font-family: 'Alike', sans-serif;
	font-size: 20px !important;
	font-weight: 400 !important;
	line-height: 30px;
	color: white !important;
	text-align: justify;
}

.distribution_img {
	width: 80% !important;
}

@media (max-width: 576px) {
	.distribution_heading {
		font-size: 30px !important;
	}

	.distribution_sub_heading {
		font-size: 20px !important;
	}

	.distribution_sub_text {
		font-size: 14px !important;
	}

	.distribution_sub_text1 {
		font-size: 14px !important;
	}
}

/* Tier Section */

.tier_section {
	background: linear-gradient(180deg, #2a5663ee 100%, #161616 30%);

	background-size: cover;

	/* margin-top: 65px !important; */
	background-size: cover !important;
	background-position: center center;

	background-repeat: no-repeat !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	position: relative;
}

.tier_img {
	width: 80%;
}

.tier_small_img_background {
	padding-top: 10px;
	background-color: transparent !important;
	background-color: transparent;
	background-image: linear-gradient(180deg, #2a5663ee 30%, #2a5663ee 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;

}

.tier_small_img {
	width: 60% !important;
}

.tier_section .main_th {
	background: linear-gradient(90deg,
			rgb(13 139 165) 3%,
			rgb(15 132 146) 26%,
			rgb(15 85 100 / 53%) 98%);
}

.tier_section tbody {
	border: none !important;
}

.tier_section th {
	background: linear-gradient(90deg,
			rgb(0 180 217) 3%,
			rgba(2, 226, 252, 0.8804564061952906) 26%,
			rgb(15 85 100 / 53%) 98%);
	padding-top: 10px !important;

	padding-bottom: 10px !important;
	border-bottom: none !important;
	font-family: 'Alike', sans-serif;
	min-width: 220px !important;
	font-size: 16px;
}

.tier_section td {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	border: none !important;
	font-family: 'Alike', sans-serif;
	min-width: 220px !important;
	font-size: 16px;
	font-weight: 500;
}

.tier_section .main_td {
	/* background: linear-gradient(
		180deg,
		rgba(15, 1, 1, 0.6787757339263831) 2%,
		rgba(81, 156, 147, 1) 46%,
		rgba(119, 185, 177, 1) 100%
	); */
	background: linear-gradient(180deg,
			rgba(15, 103, 110, 0.6787757339263831) 2%,
			rgba(81, 156, 147, 1) 46%,
			rgba(119, 185, 177, 1) 100%);
}

.tier_section .light_background {
	background: #394c53;
}

.tier_section .dark_background {
	background: #363636;
}

/* 
.tier_section .card {
	border-radius: 15px !important;
	background: #212529 !important;
}

.tier_section .th {
	background: white !important;
} */

.tier_heading {
	color: #00cdff;
	font-size: 56px !important;
	font-family: 'Alike', sans-serif;
	line-height: 77px !important;
	font-weight: 600;
	margin-bottom: 48px !important;
}

@media (max-width: 576px) {
	.tier_heading {
		font-size: 20px !important;
		line-height: 30px !important;
	}
}

.power_token_section {

	/* background: #363636 !important; */

	/*background-color: transparent;
	background-image: linear-gradient(180deg, #171f21 10%, #2a5663ee 100%),
		url(./assets/manifesto_main.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;*/
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.power_token_img {
	width: 20% !important;
}

.icon_div {
	background: linear-gradient(270deg,
			rgb(253, 121, 66) 0%,
			rgb(149, 70, 194) 48.61%,
			rgb(21, 178, 244) 100%);

	width: 70px;
	height: 70px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.power_token_section hr {
	color: white !important;
	width: 70px !important;
	border: 1px solid;
}

.power_token_section i {
	color: white !important;
}

.power_token_section .heading {
	font-family: 'Alike', sans-serif;
	font-weight: 600px !important;
	color: white !important;
	font-size: 60px !important;
}

.power_token_section i:hover {
	transform: scale(0.9);
}

.power_token_section .sub_heading {
	font-family: 'Hamlet', sans-serif;
	color: white !important;
	text-align: justify;
	font-size: 20px !important;
}

.power_token_section .sub_heading_text {
	font-family: 'Hamlet', sans-serif;
	color: lightgrey !important;
	text-align: justify;
	font-size: 16px !important;
}

.heading_div {
	background: linear-gradient(#01b1cd, #007385);
	border-radius: 100px !important;
	padding: 5px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-family: 'Alike', sans-serif;
	position: relative;
}

.power_token_section .sub_heading1 {
	color: #00cdff !important;
	font-weight: 500px !important;
}

/* .ribbon {
	position: absolute;
	width: 50px !important;
	height: 34px !important;
	background-color: #4dccc6;
	background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
	top: 0;
	left: -10px;
}

.ribbon:before {
	content: '';
	position: absolute;
	top: -10px;
	left: 0px;
	border-left: 5px solid transparent;
	border-right: 5px solid linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
	border-top: 5px solid transparent;
	border-bottom: 5px solid linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
}
.ribbon:after {
	content: '';
	position: absolute;
	top: 0;
	right: -25px;
	border-left: 25px solid linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
	border-right: 25px solid transparent;
	border-top: 25px solid transparent;
	border-bottom: 25px solid transparent;
} */

@media (max-width: 991px) {
	.power_token_section .heading {
		font-size: 40px !important;
	}
}

@media (max-width: 768px) {
	.power_token_section .heading {
		font-size: 30px !important;
	}
}

@media (max-width: 576px) {
	.power_token_section .heading {
		font-size: 20px !important;
	}
}

/* 
Purchase Token */
.purchase_token_section {
	/* background: url(./assets/joinus.jpg); */
	background-color: transparent;

	background-image: linear-gradient(180deg, #2a5663ee 100%, #171f21 20%),
		url(./assets/banner.jpg);
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;
	display: flex !important;
	justify-content: center !important;
	align-items: center;
}

.purchase_card {
	/* border: 3px solid #00cdff; */
	border-radius: 5px !important;
	height: 280px;
	width: 200px;
	border: 2px solid #00cdff;
}

.purchase_card img {
	width: 100%;
	/* or any custom size */
	height: 100%;
	object-fit: cover;
}

li.react-multi-carousel-item {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
}



.distribution_section {
	background-color: transparent;
	background-image: linear-gradient(180deg, #161616 10%, #2a5663ee 100%),
		url(./assets/manifesto_main.jpg);
	/* min-height: 500px !important; */
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;

	mix-blend-mode: multiply;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	position: relative;
}

/* Distribution Sub Section */

@media (max-width: 991px) {
	.tier_small_img {
		width: 100% !important;
	}
}

.contract_link {
	cursor: pointer;
}

.player-wrapper {
	position: relative;

	/* 720 / 1280 = 0.5625 */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}


/*yodha page*/

.bodyyodha {
	text-size-adjust: 100%;
	padding-top: 0px;
	position: relative;
	background-color: rgb(14, 12, 29);
}

.yodha_logo {
	width: 30% !important;
}

.yodha_playstore {
	width: 20% !important;
}

.yodha_playstore1 {
	padding-left: 10px;
	width: 15% !important;
}



.myteam {
	width: 45% !important;
}


.yodha_all {
	width: 80% !important;
}

.yodha_section1 {
	background-position: center center;
	background-repeat: repeat;
	background-size: cover;
	margin: 0%;
}

.myteamsec p {
	font-size: 18px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;

	/*padding-left: 40px;
	padding-right: 40px;*/
}

.yodha_section1 p {
	font-size: 22px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;
	line-height: 35px !important;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 20px;
}

.yodha_section1 h2 {
	font-size: 22px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;
	line-height: 35px !important;
	padding-left: 40px;
	padding-right: 40px;
}

.yodha_section1 h3 {
	font-size: 18px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;
	line-height: 35px !important;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 20px;
}

.yodha_heading {
	color: white;
	font-size: 56px !important;
	font-family: 'Alike', sans-serif;
	line-height: 77px !important;
	font-weight: 600;
	padding-top: 20px !important;
}

.yodha_collect {
	position: relative;
	color: rgb(255, 255, 255);
	border-radius: 0px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background-image: linear-gradient(rgba(119, 214, 255, 0.093) 0%, rgba(202, 169, 255, 0.08) 100%);
	box-shadow: rgb(0 0 0 / 8%) 0px 8px 8px;
}

.yodha_collect p {
	font-size: 22px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;
	line-height: 35px !important;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
	.myteam {
		width: 35% !important;
	}

	.myteamsec p {
		font-size: 14px !important;
		font-family: 'Hahmlet', sans-serif;
		color: #e8ebec !important;
		line-height: 26px !important;
	}

	.yodha_section1 p {
		font-size: 14px !important;
		font-family: 'Hahmlet', sans-serif;
		color: #e8ebec !important;
		line-height: 26px !important;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 20px;
	}

	.yodha_collect p {
		font-size: 14px !important;
		font-family: 'Hahmlet', sans-serif;
		color: #e8ebec !important;
		line-height: 26px !important;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 20px;
	}

	.yodha_heading {
		color: white;
		font-size: 26px !important;
		font-family: 'Alike', sans-serif;
		line-height: 50px !important;
		font-weight: 400;
		padding-top: 20px !important;
	}

	yodha_section1 h2 {
		font-family: 'Hahmlet', sans-serif;
		font-size: 18px !important;
		color: #e8ebec !important;
		line-height: 16px !important;
		padding-left: 40px;
		padding-right: 40px;
	}

	.yodha_section1 h3 {
		font-size: 16px !important;
		font-family: 'Hahmlet', sans-serif;
		color: #e8ebec !important;
		line-height: 26px !important;
		padding-left: 40px;
		padding-right: 40px;
	}


}

.divider {
	height: 2px;
	width: 40%;
	background-image: linear-gradient(90deg, rgba(171, 84, 244, 0), rgba(171, 84, 244, 0.5) 25%, rgb(255, 255, 255) 50%, rgba(171, 84, 244, 0.5) 75%, rgba(171, 84, 244, 0));
	align-self: center;
	margin-left: auto;
	margin-right: auto;
	padding-left: 16px;
	padding-right: 16px;
}

.yodha_collect .swiper-container {
	/*width: 80%;
	padding-bottom: 4vw;
	padding-top: 2vw;
	position: relative;*/
	width: 100%;
	/*padding-top: 1rem;*/
	user-select: none;
}


.yodha_collect .swiper-slide {

	background-position: center;
	background-size: cover;
	width: 650px;
	height: 450px;

}

.yodha_collect .swiper-slide-active {
	background-position: center;
	background-size: cover;
	width: 800px;
	height: 600px;

}

.yodha_collect .swiper-slide-prev {
	background-position: center;
	background-size: cover;
	width: 700px;
	height: 500px;

}

.yodha_collect .swiper-slide-next {
	background-position: center;
	background-size: cover;
	width: 700px;
	height: 500px;

}


.yodha_collect .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	/*-o-object-fit: cover;*/
	object-fit: contain;
}

.swiper-pagination-fraction {
	bottom: 0;
}

.yodha_collect .swiper-pagination-bullet {
	width: 25px;
	height: 5px;
	border-radius: 1px;
}



@media (max-width: 768px) {
	.yodha_collect .swiper-container {
		padding-bottom: 8vw;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.art_work1 {
	/*width: 80%;*/
	width: 100% !important;
	height: 850px !important;
	background-image: url(./assets/A2.png);
	background-position: center center !important;
	background-size: cover;
	background-repeat: no-repeat;
}

.art_work2 {
	/*width: 80%;*/
	width: 100% !important;
	height: 850px !important;
	background-image: url(./assets/A3.png);
	background-position: center center !important;
	background-size: cover;
	background-repeat: no-repeat;
}

@media (max-width: 768px) {
	.yodha_collect .swiper-slide {
		width: 140px;
		height: 250px;
	}

	.art_work2 {
		/*width: 80%;*/
		background-position: center left !important;
		height: 260px !important;
	}

	.art_work1 {
		/*width: 80%;*/
		background-position: center left !important;
		height: 260px !important;
	}
}



/*.Swiper {
	width: 100%;
	height: 50%;
	padding-top: 1.5rem;
	padding-bottom: 3rem;
	user-select: none;
}*/

.playtoearn {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}


.play_col1 h5 {
	padding-top: 25px;
	color: white;
	font-size: 40px !important;
	font-family: 'Alike', sans-serif;
	line-height: 70px !important;
	font-weight: 400;
	text-align: center;
}

.play_col2 h5 {
	padding-top: 25px;
	color: white;
	font-size: 40px !important;
	font-family: 'Alike', sans-serif;
	line-height: 70px !important;
	font-weight: 400;
	text-align: center;
}

.play_col1 p {
	font-size: 14px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #e8ebec !important;
	line-height: 26px !important;
	line-height: 35px !important;
	text-align: center;
}

.play_col2 p {
	font-size: 14px !important;
	font-family: 'Hahmlet', sans-serif;
	color: #baced4 !important;
	line-height: 26px !important;
	line-height: 35px !important;
	text-align: center;
}

.play_col {
	height: 546px !important;
}

.play__right_img {
	height: 450px !important;
	background-image: url(./assets/Play2.png);
	background-position: center center !important;
	background-size: contain;
	background-repeat: no-repeat;
}

.play__left_img {
	padding-top: 15px;
	height: 450px !important;
	/*background-image: url(./assets/Play1.png);*/
	background-position: center center !important;
	background-size: contain;
	background-repeat: no-repeat;
}

.play__left1_img {
	height: 450px !important;
	background-image: url(./assets/Play3.png);
	background-position: center center !important;
	background-size: contain;
	background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {

	.play_col p {
		color: #e8ebec;
		font-size: 14px !important;
	}

	.play_col h5 {
		color: white;
		font-size: 15px !important;
	}

	.play_col {
		height: auto !important;
	}

	.play__right_img {
		height: 305px !important;
	}

	.play__left_img {
		height: 300px !important;
	}

	.play__left1_img {
		height: 305px !important;
	}
}

.third_navbar {
	background-color: #1c2f34 !important;
	background-image: linear-gradient(to bottom, #161e20, rgb(43, 86, 91, 0.5));
	align-items: center;
	text-align: center;
	display: block;
	justify-content: center;
}

.navbar_third_logo {
	align-items: center;
	text-align: center;
	display: block;
	justify-content: center;
}


@media screen and (max-width: 367px) {

	.navbar_third_logo {
		width: 172px !important;
	}

	/*.exp_btn {
		padding-top: 50px;
		padding-left: 0px;
	}*/
}

/*.exp_btn {
	padding-top: 250px;
	padding-left: 1050px;
}(*/






a:focus,
button:focus {
	outline: none;
}

a:hover {
	text-decoration: none;
}

.border-btn {
	display: inline-block;
	position: relative;
}

a {
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
}

a {
	color: inherit;
}

a,
i {
	border: none;
}

a {
	background-color: transparent;
}

.border-btn:hover:before,
.border-btn:hover .border-btn__inner:before {
	transform: scaleX(0);
}

.border-btn:before {
	width: 100%;
	height: 1px;
	left: 0;
	top: 0;
	transform: scaleX(1);
	transform-origin: right;
}

.border-btn:hover:after,
.border-btn:hover .border-btn__inner:after {

	transform: scaleY(0);
}

.border-btn:after {
	width: 1px;
	height: 100%;
	top: 0;
	right: 0;
	transform: scaleY(1);
	transform-origin: bottom;
}

.border-btn:hover .border-btn__inner,
.border-btn.black:hover .border-btn__inner {
	color: blue;
}

.border-btn__inner {
	display: inline-block;
	padding: 20px 33px 20px 40px;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 7px;
	line-height: 1.2;
	text-transform: uppercase;
	color: #fff;
	transition: .5s cubic-bezier(.17, .85, .438, .99);
}

.border-btn__inner:before {
	width: 100%;
	height: 1px;
	left: 0;
	bottom: 0;
	transform: scaleX(1);
	transform-origin: left;
}

.border-btn__inner:after {
	width: 1px;
	height: 100%;
	left: 0;
	top: 0;
	transform: scaleY(1);
	transform-origin: top;
}

.border-btn:before,
.border-btn:after,
.border-btn__inner:before,
.border-btn__inner:after {
	content: '';
	position: absolute;
	background-color: #fff;
	transition: .5s cubic-bezier(.17, .85, .438, .99);
}

.border-btn:hover .border-btn__lines-1:before,
.border-btn:hover .border-btn__lines-2:before {
	transform: scaleX(1);
}

.border-btn__lines-1:before {
	width: 100%;
	height: 1px;
	left: 0;
	top: 0;
	transform: scaleX(0);
	transform-origin: left;
}

.border-btn:hover .border-btn__lines-1:after,
.border-btn:hover .border-btn__lines-2:after {
	transform: scaleY(1);
}

.border-btn__lines-1:after {
	width: 1px;
	height: 100%;
	top: 0;
	right: 0;
	transform: scaleY(0);
	transform-origin: top;
}

.border-btn__lines-2:before {
	width: 100%;
	height: 1px;
	left: 0;
	bottom: 0;
	transform: scaleX(0);
	transform-origin: right;
}

.border-btn__lines-2:after {
	width: 1px;
	height: 100%;
	left: 0;
	top: 0;
	transform: scaleY(0);
	transform-origin: bottom;
}

.border-btn__lines-1:before,
.border-btn__lines-1:after,
.border-btn__lines-2:before,
.border-btn__lines-2:after {
	content: '';
	position: absolute;
	background-color: blue;
	transition: .5s cubic-bezier(.17, .85, .438, .99);
}

/*-------------------------------*/